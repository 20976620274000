import React from "react";

import Helmet from "react-helmet";

import { useCreateFullUrl } from "../hook/useCreateFullUrl";

type Props = {
  description: string;
  urlPath: string;
  imagePath?: string;
  title?: string;
  urlAsCanonical?: boolean;
};

export const SEO = ({
  title,
  description,
  urlPath,
  imagePath,
  urlAsCanonical = false,
}: Props) => {
  const { createFullUrl } = useCreateFullUrl();

  const fullUrl = createFullUrl(urlPath);
  const fullImageUrl = createFullUrl(imagePath);

  return (
    <Helmet>
      {title ? <title>{title}</title> : null}
      <meta name="description" content={description} />
      <meta name="og:description" content={description} />
      {fullUrl && <meta name="og:url" content={fullUrl?.toString()} />}
      {fullImageUrl && (
        <meta property="og:image" content={fullImageUrl.toString()} />
      )}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      {fullUrl && <meta name="twitter:url" content={fullUrl?.toString()} />}
      <meta name="twitter:description" content={description} />
      {fullImageUrl && (
        <meta name="twitter:image" content={fullImageUrl?.toString()} />
      )}
      {fullUrl && urlAsCanonical && (
        <link rel="canonical" href={fullUrl?.toString()} />
      )}
    </Helmet>
  );
};
