export const clipLongText = (text: string, stripBreakpoint: number) => {
  const shouldStrip = text.length > stripBreakpoint;

  if (!shouldStrip) {
    return text;
  }

  // find next space character after strip breakpoint
  const nextSpaceIndex = text.slice(stripBreakpoint).indexOf(" ");
  const stripIndex = stripBreakpoint + nextSpaceIndex;

  return `${text.slice(0, stripIndex)}...`;
};
