import * as React from "react";
import PropTypes from "prop-types";

import clsx from "clsx";

const SectionContainer = ({ className = "", children }) => {
  return (
    <div className={clsx("container flex flex-col flex-wrap", className)}>
      {children}
    </div>
  );
};

SectionContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default SectionContainer;
