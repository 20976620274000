import { graphql, useStaticQuery } from "gatsby";

type SiteMetadata = {
  title: string;
  description: string | null;
  siteUrl: string;
};

type QueryResult = {
  site: { siteMetadata: SiteMetadata };
};

const useSiteMetadata = () => {
  const { site } = useStaticQuery<QueryResult>(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `);

  return site?.siteMetadata;
};

export { useSiteMetadata };
