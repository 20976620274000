/* eslint-disable tailwindcss/migration-from-tailwind-2 */
import React, { useState } from "react";

import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import clsx from "clsx";

import Headline from "../components/Headline";
import Layout from "../components/Layout";
import PhotoPopup from "../components/PhotoPopup";
import SectionContainer from "../components/SectionContainer";
import { SEO } from "../components/SEO";
import useTheme from "../hook/useTheme";
import { getHeroSectionPreviewImageSrc } from "../shared/utils/image";

import { clipLongText } from "./../shared/utils/text";

const Gallery = ({ data }) => {
  const theme = useTheme(5);

  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);

  const directusGallery = data.directus?.gallery?.gallery_sections ?? [];

  const findPhotoCollectionOrIndex = (searchElement) => {
    const locationOfPhotoCollection = directusGallery.find((section) =>
      section.photos.find(
        (item) => item.directus_files_id?.id === searchElement
      )
    );

    const indexOfPhoto = locationOfPhotoCollection?.photos.findIndex(
      (photo) => photo.directus_files_id?.id === searchElement
    );

    return { locationOfPhotoCollection, indexOfPhoto };
  };

  const photosCollection = findPhotoCollectionOrIndex(selectedItem);

  return (
    <>
      {currentPhotoIndex !== null ? (
        <PhotoPopup
          photo={getImage(
            photosCollection.locationOfPhotoCollection?.photos[
              currentPhotoIndex
            ]?.directus_files_id?.imageFile
          )}
          photoAlt={
            photosCollection.locationOfPhotoCollection?.photos[
              currentPhotoIndex
            ]?.directus_files_id?.title
          }
          additionalDescription={
            photosCollection.locationOfPhotoCollection?.photos[
              currentPhotoIndex
            ]?.directus_files_id?.description
          }
          onClose={() => setCurrentPhotoIndex(null)}
          theme={theme}
          arrowEnabled={true}
          photos={photosCollection.locationOfPhotoCollection?.photos}
          currentPhotoIndex={currentPhotoIndex}
          onPhotoChange={setCurrentPhotoIndex}
        />
      ) : null}
      <Layout theme={theme} pageTitle="Galeria">
        <SEO
          urlPath="/galeria/"
          imagePath={getHeroSectionPreviewImageSrc(data)}
          description="Galeria naszej strony. Tutaj umieszczamy zdjęcia z wydarzeń, czy też z życia naszych zwierzaków – tych adoptowanych oraz obecnych podopiecznych."
        />
        <div className="mx-auto px-2 pt-8 md:px-8 xl:px-16">
          <SectionContainer
            className={clsx(
              "mx-auto rounded-t-md px-3 py-5",
              theme.bgStyleLight
            )}
          >
            <Headline title="Galeria" level={1} className="mb-4 font-bold" />
            <div className="bg-white p-4">
              {directusGallery.map((section) => {
                const sectionItems = section.photos?.map(
                  (item) => item?.directus_files_id
                );
                return (
                  <div key={section?.id}>
                    <Headline
                      title={section?.title}
                      level={2}
                      className="mb-4 font-bold"
                    />
                    <div className="my-6 grid grid-cols-2 gap-6 lg:grid-cols-3">
                      {sectionItems.map((photo) => {
                        const photoSrc =
                          photo?.imageFile?.childImageSharp?.gatsbyImageData
                            ?.images?.fallback?.src;
                        return (
                          <button
                            onClick={() => {
                              setCurrentPhotoIndex(
                                findPhotoCollectionOrIndex(photo.id)
                                  .indexOfPhoto
                              );
                              setSelectedItem(photo.id);
                            }}
                            key={photo.id}
                            className="flex transform cursor-pointer items-center justify-center rounded-md duration-300 ease-in-out hover:scale-105"
                            style={{
                              backgroundImage: `url("${photoSrc}")`,
                            }}
                          >
                            <span className="fixed z-10 h-full w-full bg-opacity-10 backdrop-blur-sm" />
                            {/* Display the title only if the description is filled in */}
                            {photo.description ? (
                              <span className="fixed bottom-0 z-30 w-full bg-black bg-opacity-50 p-1 text-sm text-white lg:p-3">
                                {photo.title && (
                                  <h2 className="text-left font-bold">
                                    {photo.title}
                                  </h2>
                                )}
                                {photo.description && (
                                  <p className="hidden text-left font-light lg:block">
                                    {clipLongText(
                                      photo?.description ?? "",
                                      100
                                    )}
                                  </p>
                                )}
                              </span>
                            ) : null}
                            <GatsbyImage
                              className="z-20  max-h-10-screen lg:aspect-square lg:max-h-40-screen"
                              image={getImage(photo?.imageFile)}
                              alt={photo?.description ?? photo?.title}
                            />
                          </button>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </SectionContainer>
        </div>
      </Layout>
    </>
  );
};

export default Gallery;

export const query = graphql`
  query Gallery {
    directus {
      gallery {
        gallery_sections {
          id
          title
          photos {
            id
            directus_files_id {
              id
              title
              description
              imageFile {
                id
                size
                absolutePath
                childImageSharp {
                  gatsbyImageData(width: 1200)
                }
              }
            }
          }
        }
      }
      hero_section {
        hero_image {
          id
          imageFile {
            previewImage: childImageSharp {
              resize(
                width: 1200
                height: 630
                quality: 85
                cropFocus: ATTENTION
                fit: COVER
              ) {
                src
              }
            }
          }
        }
      }
    }
  }
`;
