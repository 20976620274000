import { useSiteMetadata } from "./useSiteMetadata";

const useCreateFullUrl = () => {
  const { siteUrl } = useSiteMetadata();

  const createFullUrl = (path?: string) =>
    path ? new URL(path, siteUrl) : null;

  return { createFullUrl };
};

export { useCreateFullUrl };
